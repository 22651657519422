import * as THREE from "three";

/**
 * Returns the first element that is a descendant of the root element
 * that matches the specified selector, or group of selectors.
 *
 * @param {string} s The selector to query the DOM with.
 * @return {HTMLElement|null} The first matching element or null if no element found.
 */
const select = (s) => {
  return document.querySelector(s);
};

/**
 * Returns a NodeList of elements that are descendants of the root element
 * and match the specified selector, or group of selectors.
 *
 * @param {string} s The selector to query the DOM with.
 * @return {NodeList} A NodeList of matching elements.
 */
const selectAll = (s) => {
  return document.querySelectorAll(s);
};

/**
 * Creates a new HTML element with the specified tag name.
 *
 * @param {string} element The tag name of the HTML element to create.
 * @return {HTMLElement} The newly created HTML element.
 */
const create = (element) => {
  return document.createElement(element);
};

/**
 * Computes the 3D position of an element on the surface of a sphere,
 * given the element's bounding rectangle and the radius of the sphere.
 *
 * @param {HTMLElement} element The element to position on the sphere.
 * @param {number} sphereRadius The radius of the sphere.
 * @return {THREE.Vector3} The 3D position of the element on the sphere.
 */
function get3DPositionOnSphere(element, sphereRadius) {
  const rect = element.getBoundingClientRect();
  const x = (rect.left + rect.width / 2) / window.innerWidth - 0.5;
  const y = (rect.top + rect.height / 2) / window.innerHeight - 0.5;

  // Convert screen position to spherical coordinates
  const phi = x * Math.PI * 2; // azimuthal angle
  const theta = y * Math.PI; // polar angle

  // Convert spherical coordinates to Cartesian coordinates
  const posX = sphereRadius * Math.sin(theta) * Math.cos(phi);
  const posY = sphereRadius * Math.sin(theta) * Math.sin(phi);
  const posZ = sphereRadius * Math.cos(theta);

  return new THREE.Vector3(posX, posY, posZ);
}

export { select, selectAll, create, get3DPositionOnSphere };

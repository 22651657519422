import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { select, selectAll } from "./_utils";
import * as THREE from "three";
import {
  FontLoader,
  OrbitControls,
  TrackballControls,
} from "three/examples/jsm/Addons.js";
import { TextGeometry } from "three/examples/jsm/Addons.js";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const overlay = select(".overlay");
const closeModalBtn = select(".close__btn");
const logo = select(".logo");

let scene,
  camera,
  renderer,
  geometry,
  controls,
  sphere1,
  sphere2,
  sphere3,
  clock,
  light,
  group,
  font,
  fontLoader,
  textGroup,
  raycaster,
  container,
  mouse,
  trackball;

mouse = new THREE.Vector2();

const sphereRadius = 0.8;
const zoomThreshold = 10;

container = select(".container");

raycaster = new THREE.Raycaster();

scene = new THREE.Scene();

camera = new THREE.PerspectiveCamera(
  50,
  window.innerWidth / window.innerHeight,
  0.1,
  1000,
);

renderer = new THREE.WebGLRenderer({
  canvas: select("#canvas"),
  antialias: true,
  stencil: true,
});

geometry = new THREE.OctahedronGeometry(1, 4);

const materialOptions = {
  color: "#636363",
  wireframe: true,
  roughness: 0.8,
  fog: true,
  transparent: true,
  opacity: 0.2,
};

controls = new OrbitControls(camera, renderer.domElement);

trackball = new TrackballControls(camera, renderer.domElement);

sphere1 = new THREE.Mesh(
  geometry,
  new THREE.MeshStandardMaterial(materialOptions),
);
sphere2 = new THREE.Mesh(
  geometry,
  new THREE.MeshStandardMaterial(materialOptions),
);
sphere3 = new THREE.Mesh(
  geometry,
  new THREE.MeshStandardMaterial(materialOptions),
);

sphere1.scale.set(1.2, 1.2, 1.2);
sphere2.scale.set(0.8, 0.8, 0.8);
sphere3.scale.set(2, 2, 2);
sphere1.position.set(0.5, 2.5, 1.5);
sphere2.position.set(-1.5, 0.5, -2.5);
sphere3.position.set(-0.5, -3, 1);

clock = new THREE.Clock();

light = new THREE.AmbientLight(0x555555, 10);
light.position.set(1, 1, 1);

group = new THREE.Group();

fontLoader = new FontLoader();
font = null;
textGroup = [];

camera.position.set(-10, 5, 5);
controls.update();
controls.enableDamping = true;
controls.enableZoom = true;
controls.zoomToCursor = true;
controls.dampingFactor = 0.12;
controls.minDistance = -100;
controls.maxDistance = 30;
controls.rotateSpeed = 0.22;
controls.zoomSpeed = 2.67;
controls.enablePan = true;
controls.enableRotate = true;
controls.minPolarAngle = 0;
controls.maxPolarAngle = Math.PI;
controls.minAzimuthAngle = -Math.PI;
controls.maxAzimuthAngle = Math.PI;
trackball.update();
trackball.noRotate = true;
trackball.noPan = true;
trackball.noZoom = false;
trackball.zoomSpeed = 0.2;
renderer.setSize(window.innerWidth, window.innerHeight);
container.appendChild(renderer.domElement);
group.add(sphere1, sphere2, sphere3);
scene.add(light, group);
scene.background = new THREE.Color(0x121212);

async function loadFont() {
  font = await fontLoader.loadAsync(
    "https://raw.githubusercontent.com/mrdoob/three.js/master/examples/fonts/helvetiker_bold.typeface.json",
  );

  for (const t of ["about", "contacts", "guitars"]) {
    const textGeometry = new TextGeometry(t.toUpperCase(), {
      font: font,
      size: 0.1,
      depth: 0.001,
      curveSegments: 10,
    });

    textGeometry.center();

    const textMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0.2,
    });

    const textMesh = new THREE.Mesh(textGeometry, textMaterial);

    switch (t) {
      case "about":
        textMesh.position.set(0.5, 2.5, 1.5);
        break;
      case "contacts":
        textMesh.position.set(-1.5, 0.5, -2.5);
        break;
      case "guitars":
        textMesh.position.set(-0.5, -3, 1);
        break;
    }

    textGroup.push(textMesh);
    group.add(textMesh);
  }
}

let animateId;

const endAnimate = () => {
  cancelAnimationFrame(animateId);
};

function animate() {
  animateId = requestAnimationFrame(animate);
  const { x, y, z } = controls.target;
  controls.update();
  trackball.target.set(x, y, z);
  trackball.update();
  renderer.render(scene, camera);
  raycaster.setFromCamera(mouse, camera);
  updateCursor();
}

let tickId;
let selectedSphere;
let lastCameraPosition;

function tick() {
  tickId = requestAnimationFrame(tick);
  const delta = clock.getDelta();
  sphere1.rotation.y += delta * 0.05;
  sphere2.rotation.x += delta * 0.05;
  sphere3.rotation.y -= delta * 0.05;
  renderer.render(scene, camera);
}

const endTick = () => {
  cancelAnimationFrame(tickId);
};

function updateCursor() {
  let isIntersecting = false;
  [sphere1, sphere2, sphere3].forEach((sphere) => {
    const intersects = raycaster.intersectObject(sphere);
    if (intersects.length) {
      isIntersecting = true;
      gsap.to(sphere.material, { opacity: 1, duration: 1 });
    } else {
      gsap.to(sphere.material, { opacity: 0.2, duration: 0.5 });
    }
  });
  if (isIntersecting) {
    container.style.cursor = "pointer";
  } else {
    container.style.cursor = "auto";
  }
}

const onDOMContentLoaded = async function () {
  await loadFont();
  animate();
  tick();
};

const onResize = () => {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
};

const introAnim = () => {
  gsap.to(controls.target, {
    x: -0.1,
    y: 1.3,
    z: 0.7,
    duration: 0.5,
    ease: "power3.inOut",
  });
};

let isModalOpen = false;

const openModal = () => {
  gsap.to(overlay, {
    opacity: 0.7,
    transform: "translateX(0)",
    duration: 1,
    ease: "power3.inOut",
    delay: 1,
    onStart: () => (isModalOpen = true),
  });
};

const closeModal = () => {
  gsap.to(overlay, {
    opacity: 1,
    transform: "translateX(100%)",
    duration: 1,
    ease: "power3.inOut",
    onStart: () => (isModalOpen = false),
  });
};

const goTo = (x, y, z, action) => {
  const isEnter = action === "exit" ? false : true;

  gsap.to(camera.position, {
    x: x,
    y: y,
    z: isEnter ? z : z + 5,
    duration: 2,
    ease: "power3.inOut",
    onStart: () => {
      if (isEnter) openModal();
    },
    onUpdate: () => {
      camera.updateProjectionMatrix();
    },
  });
};

const writeText = () => {
  const title = select(".modal__title h1");
  const sphereContent1 = select("#sphere1");
  const sphereContent2 = select("#sphere2");
  const sphereContent3 = select("#sphere3");

  switch (selectedSphere) {
    case sphere1:
      title.textContent = "About";
      sphereContent1.style.display = "block";
      sphereContent2.style.display = "none";
      sphereContent3.style.display = "none";
      break;
    case sphere2:
      title.textContent = "Contacts";
      sphereContent1.style.display = "none";
      sphereContent2.style.display = "block";
      sphereContent3.style.display = "none";
      break;
    case sphere3:
      title.textContent = "Guitars";
      sphereContent1.style.display = "none";
      sphereContent2.style.display = "none";
      sphereContent3.style.display = "block";
      break;
  }
};

const onClick = () => {
  raycaster.setFromCamera(mouse, camera);
  [sphere1, sphere2, sphere3].forEach((sphere) => {
    const intersects = raycaster.intersectObject(sphere);
    const { x, y, z } = sphere.position;
    if (intersects.length && !isModalOpen) {
      lastCameraPosition = camera.position;
      selectedSphere = sphere;
      goTo(x, y, z);
      gsap.to(selectedSphere.material, {
        opacity: 1,
        duration: 2,
        ease: "power3.inOut",
      });
      writeText();
    }
  });
};

closeModalBtn.onclick = (e) => {
  e.stopPropagation();
  closeModal();
  const { x, y, z } = lastCameraPosition;
  goTo(x, y, z, "exit");
};

logo.onclick = (e) => {
  e.stopPropagation();
  goTo(-10, 5, 5, "exit");
};

const onMouseMove = (event) => {
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
};

window.addEventListener("click", onClick);
window.addEventListener("DOMContentLoaded", onDOMContentLoaded);
window.addEventListener("resize", onResize);
window.addEventListener("mousemove", onMouseMove);
